import {clickEventService} from 'core-tracking-objects';

class ClickEventObjectHeaderLogo {
	/**
	* registers to ClickEventService
	* @constructor singleton
	*/
	constructor() {
		if (ClickEventObjectHeaderLogo._instance) {
			return ClickEventObjectHeaderLogo._instance;
		}

		this.getTrackingData = this.getTrackingData.bind(this);

		clickEventService.register(this.getTrackingData);

		ClickEventObjectHeaderLogo._instance = this;
	}

	/**
	* getTrackingData - collects the tracking data
	* @param {Object} eventData_ - event data
	* @returns {Promise} promise - returns promise for linkObject
	*/
	async getTrackingData(eventData_) {
		if (!eventData_ || !eventData_.currentTarget || !eventData_.currentTarget.classList ||
			!eventData_.currentTarget.classList.contains('header-logo__link')) {
			return {};
		}

		return {
			eventInfo: {
				eventAction: "internal_link",
				eventName: "generic"
			},
			attributes: {
				componentName: "one-header",
				currentURL: window.location.href,
				elementName: "icon",
				label: "Audi Logo",
				targetURL: eventData_.currentTarget.getAttribute('href')
			}
		};
	}
}

/**
* Singleton instance
* @type {ClickEventObjectHeaderLogo}
* @public
* @static
*/
const clickEventObjectHeaderLogo = new ClickEventObjectHeaderLogo();
export {clickEventObjectHeaderLogo, ClickEventObjectHeaderLogo};
