import {clickEventService} from 'core-tracking-objects';

class ClickEventObjectHeaderSearchOverlay {
	/**
	* registers to ClickEventService
	* @constructor singleton
	*/
	constructor() {
		if (ClickEventObjectHeaderSearchOverlay._instance) {
			return ClickEventObjectHeaderSearchOverlay._instance;
		}

		clickEventService.registerClickSelector('.header-search-button__button');
		clickEventService.registerClickSelector('.header-search__close');

		this.getTrackingData = this.getTrackingData.bind(this);
		clickEventService.register(this.getTrackingData);

		ClickEventObjectHeaderSearchOverlay._instance = this;
	}

	/**
	* getTrackingData - collects the tracking data
	* @param {Object} eventData_ - event data
	* @returns {Promise} promise - returns promise for linkObject
	*/
	async getTrackingData(eventData_) {
		if (!eventData_ || !eventData_.currentTarget || !eventData_.currentTarget.classList ||
			!eventData_.currentTarget.classList.contains('header-search-button__button') &&
			!eventData_.currentTarget.classList.contains('header-search__close'))	{
			return {};
		}

		return {
			eventInfo: {
				eventAction: "navigation",
				eventName: "main search - " + this._getClickType(eventData_.currentTarget.classList)
			},
			attributes: {
				componentName: "one-header",
				currentURL: window.location.href,
				elementName: "text link",
				label: this._getLabel(eventData_.currentTarget),
				pos: this._getSearchButtonPosition(eventData_.currentTarget)
			}
		};
	}

	/**
	* Returns the click type that was performed
	* @param {string} classList_ - classList of clicked item
	* @returns {string} open | access
	* @private
	*/
	_getClickType(classList_) {
		return classList_.contains('header-search__close') ? 'close' : 'access';
	}

	/**
	* Returns the text content of the clicked element if one exists
	* @param {HTMLElement} element_ - clicked item
	* @returns {string} label
	* @private
	*/
	_getLabel(element_) {
		let label = "";

		const header = element_.closest('.header');

		if (header.querySelector('.header-search-button__label') && header.querySelector('.header-search-button__label').textContent) {
			label = header.querySelector('.header-search-button__label').textContent;
		}
		return label;
	}

	/**
	* Returns search button position within header
	* @param {HTMLElement} searchButton_ - clicked item
	* @returns {string} label
	* @private
	*/
	_getSearchButtonPosition(searchButton_) {
		let position = 1;
		const header = searchButton_.closest('.header');
		const navList = header.querySelector('.header-nav__list');

		if (navList && navList.children) {
			position += navList.children.length;
		}

		return position.toString();
	}
}

/**
* Singleton instance
* @type {ClickEventObjectHeaderSearchOverlay}
* @public
* @static
*/
const clickEventObjectHeaderSearchOverlay = new ClickEventObjectHeaderSearchOverlay();
export {clickEventObjectHeaderSearchOverlay, ClickEventObjectHeaderSearchOverlay};
