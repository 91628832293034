import {clickEventService} from 'core-tracking-objects';

class ClickEventObjectHeaderLogin {
	/**
	* registers to ClickEventService
	* @constructor singleton
	*/
	constructor() {
		if (ClickEventObjectHeaderLogin._instance) {
			return ClickEventObjectHeaderLogin._instance;
		}

		this.getTrackingData = this.getTrackingData.bind(this);

		clickEventService.register(this.getTrackingData);

		ClickEventObjectHeaderLogin._instance = this;
	}

	/**
	* getTrackingData - collects the tracking data
	* @param {Object} eventData_ - event data
	* @returns {Promise} promise - returns promise for linkObject
	*/
	async getTrackingData(eventData_) {
		if (!eventData_ || !eventData_.currentTarget || !eventData_.currentTarget.classList ||
			!eventData_.currentTarget.classList.contains('header-login__link')) {
			return {};
		}

		return {
			eventInfo: {
				eventAction: "exit_link",
				eventName: "generic"
			},
			attributes: {
				componentName: "one-header",
				currentURL: window.location.href,
				elementName: "icon",
				label: "myAudi",
				pos: this._getLoginLinkPosition(eventData_.currentTarget),
				targetURL: eventData_.currentTarget.getAttribute('href')
			}
		};
	}

	/**
	* Returns the current position of the login Link within header
	* @param {HTMLElement} loginElement_ - clicked item
	* @returns {string} label
	* @private
	*/
	_getLoginLinkPosition(loginElement_) {
		let position = 1;
		const header = loginElement_.closest('.header');
		const navList = header.querySelector('.header-nav__list');

		if (navList && navList.children) {
			position += navList.children.length;
		}

		if (header.querySelector('.header-search')) {
			position += 1;
		}

		return position.toString();
	}
}

/**
* Singleton instance
* @type {ClickEventObjectHeaderLogin}
* @public
* @static
*/
const clickEventObjectHeaderLogin = new ClickEventObjectHeaderLogin();
export {clickEventObjectHeaderLogin, ClickEventObjectHeaderLogin};
