class ClickEventHeaderHelper {
	/**
	 * check whether the target url is an external link or not
	 * @param {String} url_ - target url
	 * @returns {boolean} true, if external, false else
	 */
	static _isExternalLink(url_) {
		if (url_.indexOf('http') === -1 && url_.indexOf('//') === -1) {
			return false;
		}

		if (this._isLinkOfSameDomain(url_, window.location)) {
			return false;
		}

		return true;
	}

	/**
	 * check whether the target url is within the current domain
	 * @param {String} url_ - target url
	 * @param {String} origin_ - window.location
	 * @returns {boolean} true, if same domain, false if not
	 */
	static _isLinkOfSameDomain(url_, origin_) {
		let pathParts = url_.split('/');
		let hostname = origin_.hostname;
		let hostWithPort = (origin_.hostname + ':' + origin_.port);

		return (pathParts[2] === hostname || pathParts[2] === hostWithPort);
	}
}

export {ClickEventHeaderHelper};
