import {clickEventService} from 'core-tracking-objects';

class ClickEventObjectHeaderTeaser {
	/**
	* registers to ClickEventService
	* @constructor singleton
	*/
	constructor() {
		if (ClickEventObjectHeaderTeaser._instance) {
			return ClickEventObjectHeaderTeaser._instance;
		}

		this.getTrackingData = this.getTrackingData.bind(this);

		clickEventService.register(this.getTrackingData);

		ClickEventObjectHeaderTeaser._instance = this;
	}

	/**
	* getTrackingData - collects the tracking data
	* @param {Object} eventData_ - event data
	* @returns {Promise} promise - returns promise for linkObject
	*/
	async getTrackingData(eventData_) {
		if (!eventData_ || !eventData_.currentTarget || !eventData_.currentTarget.classList ||
			!eventData_.currentTarget.classList.contains('header-teaser__link')) {
			return {};
		}

		const oneHeaderElement = eventData_.currentTarget.closest('.header[data-module="one-header"]');

		return {
			eventInfo: {
				eventAction: "internal_link",
				eventName: "main navigation - teaser click"
			},
			attributes: {
				componentName: "one-header",
				label: this._getLabel(eventData_.currentTarget, oneHeaderElement),
				currentURL: window.location.href,
				targetURL: eventData_.currentTarget.getAttribute('href'),
				elementName: "image",
				pos: this._getTeaserPosition(eventData_.currentTarget)
			}
		};
	}

	/**
	* Returns the position of the navItem within the navigation
	* @param {HTMLElement} teaser_ - teaserElement
	* @param {HTMLElement} context_ - headerElement
	* @returns {number} position
	* @private
	*/
	_getLabel(teaser_, context_) {
		let label = context_.querySelector('.header-menu-button .header-menu-button__text').textContent;
		const currentNavItem = context_.querySelector(".header-nav-item[data-sub-nav-open='true']");

		if (currentNavItem) {
			label += ' > ' + currentNavItem.querySelector('.header-nav-item__link .header-nav-item__text').textContent;
		}

		label += ' > ' + teaser_.querySelector('.header-teaser__text').textContent;

		return label;
	}

	/**
	* Returns the position of the navItem within the navigation
	* @param {HTMLElement} teaser_ - teaserElement
	* @returns {number} position
	* @private
	*/
	_getTeaserPosition(teaser_) {
		const teaserWrapper = teaser_.closest(".header-sub-nav__list-teaser-wrapper");

		if (teaserWrapper) {
			const teaserPosition = (teaserWrapper.querySelector(".header-sub-nav__list").children.length + 1).toString();
			const parentNavItem = teaserWrapper.closest(".header-nav-item[data-sub-nav-open='true']");

			if (parentNavItem && parentNavItem.parentNode) {
				const parentItemPosition = (Array.prototype.indexOf.call(parentNavItem.parentNode.children, parentNavItem) + 1).toString();
				return parentItemPosition + "-" + teaserPosition;
			}
		}

		return "";
	}
}

/**
* Singleton instance
* @type {ClickEventObjectHeaderTeaser}
* @public
* @static
*/
const clickEventObjectHeaderTeaser = new ClickEventObjectHeaderTeaser();
export {clickEventObjectHeaderTeaser, ClickEventObjectHeaderTeaser};
