import {clickEventService} from 'core-tracking-objects';

class ClickEventObjectDownloadNemo {
	/**
	* registers to ClickEventService
	* @constructor singleton
	*/
	constructor() {
		if (ClickEventObjectDownloadNemo._instance) {
			return ClickEventObjectDownloadNemo._instance;
		}

		clickEventService.register(ClickEventObjectDownloadNemo.getTrackingData);

		ClickEventObjectDownloadNemo._instance = this;
	}

	/**
	* getTrackingData - collects the tracking data
	* @param {Object} eventData_ - event data
	* @returns {Promise} promise - returns promise for linkObject
	*/
	static async getTrackingData(eventData_) {
		const target = eventData_.currentTarget;

		if (!target || !ClickEventObjectDownloadNemo._linkContainsRelevantClasses(target)) {
			return {};
		}

		return {
			eventInfo: {
				eventAction: 'download'
			},
			attributes: {
				value: target.classList.contains('nm-j-zip-download-delegate-click')? 'ZIP' : 'PDF'
			}
		};
	}

	/**
	 * @param {Element} target_ - click dom Element
	 * @returns {boolean} true if one of the relevant classes exist on the target | false
	 */
	static _linkContainsRelevantClasses(target_) {
		if (!target_.classList) {
			return false;
		}

		return (target_.classList.contains('nm-j-zip-download-delegate-click') ||
			target_.classList.contains('nm-configuration-print') ||
			target_.classList.contains('j-pdf-configuration'));
	}
}

/**
 * Singleton instance
 * @type {ClickEventObjectDownloadNemo}
 * @public
 * @static
 */
const clickEventObjectDownloadNemo = new ClickEventObjectDownloadNemo();
export {clickEventObjectDownloadNemo, ClickEventObjectDownloadNemo};
