import {ClickEventHeaderHelper} from './click-event-header-helper';
import {ClickEventHelperDom, clickEventService} from 'core-tracking-objects';

class ClickEventObjectHeaderNavItem {
	/**
	* registers to ClickEventService
	* @constructor singleton
	*/
	constructor() {
		if (ClickEventObjectHeaderNavItem._instance) {
			return ClickEventObjectHeaderNavItem._instance;
		}

		this.getTrackingData = this.getTrackingData.bind(this);

		clickEventService.register(this.getTrackingData);

		ClickEventObjectHeaderNavItem._instance = this;
	}

	/**
	* getTrackingData - collects the tracking data
	* @param {Object} eventData_ - event data
	* @returns {Promise} promise - returns promise for linkObject
	*/
	async getTrackingData(eventData_) {
		const eventData = {};

		if (!eventData_ || !eventData_.currentTarget || !eventData_.currentTarget.classList ||
			!eventData_.currentTarget.classList.contains('header-nav-item__link')) {
			return eventData;
		}

		const url = ClickEventHelperDom.getTargetUrl(eventData_);
		let eventAction = "internal_link";

		if (ClickEventHeaderHelper._isExternalLink(url)) {
			eventAction = "exit_link";
		}

		const oneHeaderElement = eventData_.currentTarget.closest('.header[data-module="one-header"]');
		const menuLabel = oneHeaderElement.querySelector('.header-menu-button .header-menu-button__text').textContent;

		eventData.eventInfo = {
			eventAction: eventAction,
			eventName: "generic"
		};

		eventData.attributes = {
			componentName: "one-header",
			currentURL: window.location.href,
			elementName: "text link",
			label: menuLabel + " > " + eventData_.currentTarget.querySelector('.header-nav-item__text').textContent,
			pos: this._getItemPosition(eventData_.currentTarget),
			targetURL: eventData_.currentTarget.getAttribute("href")
		};

		// set data for flyout item
		const parentNode = eventData_.currentTarget.parentNode;
		if (parentNode && parentNode.dataset.subNavOpen) {
			eventData.eventInfo = {
				eventAction: "navigation",
				eventName: "main navigation - " + (parentNode.dataset.subNavOpen === "true" ? "close" : "open")
			};
		}

		return eventData;
	}

	/**
	* Returns the position of the navItem within the navigation
	* @param {HTMLElement} link_ - linkElement
	* @returns {number} position
	* @private
	*/
	_getItemPosition(link_) {
		const navItem = link_.parentNode;

		if (navItem && navItem.parentNode) {
			return (Array.prototype.indexOf.call(navItem.parentNode.children, navItem) + 1).toString();
		}

		return "";
	}
}

/**
* Singleton instance
* @type {ClickEventObjectHeaderNavItem}
* @public
* @static
*/
const clickEventObjectHeaderNavItem = new ClickEventObjectHeaderNavItem();
export {clickEventObjectHeaderNavItem, ClickEventObjectHeaderNavItem};
