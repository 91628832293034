import {ClickEventHeaderHelper} from './click-event-header-helper';
import {ClickEventHelperDom, clickEventService} from 'core-tracking-objects';

class ClickEventObjectHeaderSubNavItem {
	/**
	* registers to ClickEventService
	* @constructor singleton
	*/
	constructor() {
		if (ClickEventObjectHeaderSubNavItem._instance) {
			return ClickEventObjectHeaderSubNavItem._instance;
		}

		this.getTrackingData = this.getTrackingData.bind(this);

		clickEventService.register(this.getTrackingData);

		ClickEventObjectHeaderSubNavItem._instance = this;
	}

	/**
	* getTrackingData - collects the tracking data
	* @param {Object} eventData_ - event data
	* @returns {Promise} promise - returns promise for linkObject
	*/
	async getTrackingData(eventData_) {
		if (!eventData_ || !eventData_.currentTarget || !eventData_.currentTarget.classList ||
			!eventData_.currentTarget.classList.contains('header-sub-nav__item-link')) {
			return {};
		}

		const oneHeaderElement = eventData_.currentTarget.closest('.header[data-module="one-header"]');

		const url = ClickEventHelperDom.getTargetUrl(eventData_);
		let eventAction = "internal_link";

		if (ClickEventHeaderHelper._isExternalLink(url)) {
			eventAction = "exit_link";
		}

		return {
			eventInfo: {
				eventAction: eventAction,
				eventName: "generic"
			},
			attributes: {
				componentName: "one-header",
				currentURL: window.location.href,
				elementName: "text link",
				label: this._getLabel(eventData_.currentTarget, oneHeaderElement),
				pos: this._getItemPosition(eventData_.currentTarget),
				targetURL: eventData_.currentTarget.getAttribute('href')
			}
		};
	}


	/**
	* Returns the position of the navItem within the navigation
	* @param {HTMLElement} link_ - linkElement
	* @param {HTMLElement} context_ - headerElement
	* @returns {number} position
	* @private
	*/
	_getLabel(link_, context_) {
		let label = context_.querySelector('.header-menu-button .header-menu-button__text').textContent;
		const currentNavItem = context_.querySelector(".header-nav-item[data-sub-nav-open='true']");

		if (currentNavItem) {
			label += ' > ' + currentNavItem.querySelector('.header-nav-item__link .header-nav-item__text').textContent;
		}

		label += ' > ' + link_.textContent;

		return label;
	}

	/**
	* Returns the position of the navItem within the navigation
	* @param {HTMLElement} link_ - linkElement
	* @returns {number} position
	* @private
	*/
	_getItemPosition(link_) {
		const navItem = link_.parentNode;

		if (navItem && navItem.parentNode) {
			const itemPosition = (Array.prototype.indexOf.call(navItem.parentNode.children, navItem) + 1).toString();
			const parentNavItem = navItem.closest(".header-nav-item[data-sub-nav-open='true']");

			if (parentNavItem && parentNavItem.parentNode) {
				const parentItemPosition = (Array.prototype.indexOf.call(parentNavItem.parentNode.children, parentNavItem) + 1).toString();
				return parentItemPosition + "-" + itemPosition;
			}
		}

		return "";
	}
}

/**
* Singleton instance
* @type {ClickEventObjectHeaderSubNavItem}
* @public
* @static
*/
const clickEventObjectHeaderSubNavItem = new ClickEventObjectHeaderSubNavItem();
export {clickEventObjectHeaderSubNavItem, ClickEventObjectHeaderSubNavItem};
