import {clickEventService} from 'core-tracking-objects';

class ClickEventObjectHeaderMenu {
	/**
	* registers to ClickEventService
	* @constructor singleton
	*/
	constructor() {
		if (ClickEventObjectHeaderMenu._instance) {
			return ClickEventObjectHeaderMenu._instance;
		}

		clickEventService.registerClickSelector('.header-menu-button__button');

		this.getTrackingData = this.getTrackingData.bind(this);
		clickEventService.register(this.getTrackingData);

		ClickEventObjectHeaderMenu._instance = this;
	}

	/**
	* getTrackingData - collects the tracking data
	* @param {Object} eventData_ - event data
	* @returns {Promise} promise - returns promise for linkObject
	*/
	async getTrackingData(eventData_) {
		if (!eventData_ || !eventData_.currentTarget || !eventData_.currentTarget.classList ||
			!eventData_.currentTarget.classList.contains('header-menu-button__button')) {
			return {};
		}

		return {
			eventInfo: {
				eventAction: "navigation",
				eventName: "main navigation - " + this._getHeaderState(eventData_.currentTarget)
			},
			attributes: {
				componentName: "one-header",
				currentURL: window.location.href,
				elementName: "text link",
				label: eventData_.currentTarget.querySelector('.header-menu-button__text').textContent,
				pos: "0"
			}
		};
	}

	/**
	* Returns the action type that was performed when the menu button was clicked
	* @param {HTMLElement} clickTarget_ - clicked element
	* @returns {string} header state - open | closed
	* @private
	*/
	_getHeaderState(clickTarget_) {
		const headerElement = clickTarget_.closest('.header');

		return headerElement.classList.contains('header--navigation-closed') ? 'open' : 'close';
	}
}

/**
* Singleton instance
* @type {ClickEventObjectHeaderMenu}
* @public
* @static
*/
const clickEventObjectHeaderMenu = new ClickEventObjectHeaderMenu();
export {clickEventObjectHeaderMenu, ClickEventObjectHeaderMenu};
